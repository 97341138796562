<template>
	<UserEventCardComponent
		v-if="user_is_staff"
		heading="The Boundary"
		:user="option_is_staff_approved ? option.staff_approval_user[0] : null"
		:event-label="staff_approval_label"
		:event-is-pending="!option_is_staff_approved"
	/>

	<UserEventCardComponent
		heading="Client"
		:user="option_is_client_approved ? option.client_approval_user[0] : null"
		:event-label="client_approval_label"
		:event-is-pending="!option_is_client_approved"
	/>
</template>

<script setup>
import UserEventCardComponent from './UserEventCardComponent';
import { computed, defineProps } from 'vue';
import { useDataStore } from '../stores/data';
import { USER_GROUP_STAFF } from '../constants';

import moment from 'moment';

const { option } = defineProps( {
	option: Object
} );

const { user_group } = useDataStore();

const user_is_staff = computed( () => {
	return user_group === USER_GROUP_STAFF;
} );

// Staff
const option_is_staff_approved = computed( () => {
	return option && option.staff_approval_user.length;
} );

const staff_approval_label = computed( () => {
	return option_is_staff_approved ? `Approved on ${getDate( option.staff_approval_timestamp )}` : 'Approval pending';
} );

// Client
const option_is_client_approved = computed( () => {
	return option && option.client_approval_user.length;
} );

const client_approval_label = computed( () => {
	return option_is_client_approved ? `Approved on ${getDate( option.client_approval_timestamp )}` : 'Approval pending';
} );

const getDate = timestamp => {
	return moment( new Date( timestamp ) ).format( 'D/M/YY' );
};
</script>
