<template>
	<div class="asset-sidebar">
		<div class="asset-sidebar__tabs">
			<button
				class="asset-sidebar__tab"
				:class="{ 'asset-sidebar__tab--active': active_tab === 'comments' && expand }"
				@click="toggleActiveTab( 'comments' )"
			>
				<span class="asset-sidebar__tab-icon icon icon--message-circle--thin" />
			</button>
			<button
				class="asset-sidebar__tab"
				:class="{ 'asset-sidebar__tab--active': active_tab === 'approvals' && expand }"
				@click="toggleActiveTab( 'approvals' )"
			>
				<span class="asset-sidebar__tab-icon icon icon--eye--thin" />
			</button>
			<button
				class="asset-sidebar__tab asset-sidebar__tab--history"
				:class="{ 'asset-sidebar__tab--active': active_tab === 'history' && expand }"
				@click="toggleActiveTab( 'history' )"
			>
				<span class="asset-sidebar__tab-icon icon icon--history--thin" />
			</button>
			<button
				v-if="user_is_staff_super_admin"
				class="asset-sidebar__tab"
				:class="{ 'asset-sidebar__tab--active': active_tab === 'settings' && expand }"
				@click="toggleActiveTab( 'settings' )"
			>
				<span class="asset-sidebar__tab-icon icon icon--settings--thin" />
			</button>
		</div>

		<div class="asset-sidebar__content-wrapper">
			<div
				class="asset-sidebar__content"
				:class="{ 'asset-sidebar__content--loading': loading }"
			>
				<h2 class="p">
					{{ sidebar_title }}
				</h2>

				<template v-if="active_tab === 'comments' && option">
					<div class="flex flex--justify-space-between mb-16">
						<h3 class="asset-sidebar__sub-title">
							{{ image_title[0] }}
							<br>
							{{ image_title[1] }}
						</h3>
						<router-link
							v-if="active_tab === 'comments'"
							class="arrow-link p--small"
							:to="{
								name: 'projects__project',
								params: { project_id: this.$route.params.project_id },
								query: { tab: 'discussion' }
							}"
						>
							All discussions
						</router-link>
					</div>

					<div
						v-if="project_is_completed"
						class="typeset typeset--small mb-16"
					>
						<p>
							Project is now complete and further commenting has been disabled.
						</p>
					</div>
					<div
						v-else-if="asset_is_final"
						class="typeset typeset--small mb-16"
					>
						<p>
							Asset is final and further commenting has been disabled.
						</p>
					</div>
					<div
						v-else-if="option_is_staff_unapproved"
						class="typeset typeset--small mb-16"
					>
						<p>
							This upload is not approved yet so commenting has been disabled.
						</p>
					</div>
					<div
						v-else-if="option_is_in_old_stage"
						class="typeset typeset--small mb-16"
					>
						<p>
							This stage has been completed and further commenting has been disabled.
						</p>
					</div>

					<CommentFormComponent
						v-else
						:option-id="option_id"
						@comment-posted="getComments"
					/>

					<div class="asset-sidebar__comments-heading">
						<h3 class="asset-sidebar__sub-title">
							Comments
						</h3>

						<button
							v-if="comments && comments.length"
							class="comments-sort"
							:class="{ 'comments-sort--asc': date_sort === 'asc' }"
							@click="toggleDateSort"
						>
							Date
						</button>
					</div>
					<CommentsComponent
						:comments="comments"
						:showTimestamps="true"
					/>
				</template>

				<template v-if="active_tab === 'approvals' && option">
					<div class="asset-sidebar__approvals-heading">
						<h3 class="asset-sidebar__sub-title">
							{{ image_title[0] }}
							<br>
							{{ image_title[1] }}
						</h3>
					</div>

					<div class="asset-sidebar__approvals-sub-heading">
						<h4 class="asset-sidebar__sub-sub-title">
							Artist:
							<br>
							{{ option_artist }}
						</h4>
					</div>

					<div class="asset-sidebar__approvals-sub-heading">
						<h4 class="asset-sidebar__sub-sub-title">
							Uploaded on {{ option_upload_date }} by:
							<br>
							{{ option_uploader }}
						</h4>
					</div>

					<ApprovalsComponent
						:option="option"
					/>
				</template>

				<template v-if="active_tab === 'history' && asset">
					<div class="asset-sidebar__history-heading">
						<h3 class="asset-sidebar__sub-title">
							{{ image_title[0] }}
							<br>
							{{ image_title[1] }}
						</h3>
					</div>

					<AssetHistoryComponent
						:asset="asset"
					/>
				</template>

				<template v-if="active_tab === 'settings' && option">
					<button
						class="button button--fill-danger"
						:class="{ 'button--disabled': loading || asset_is_final || option_is_in_old_stage }"
						:disabled="loading || asset_is_final || option_is_in_old_stage"
						@click="show_delete_option_modal = true"
					>
						Delete selected option
					</button>

					<Teleport to="#modal">
						<Transition name="fade">
							<DeleteOptionModalComponent
								v-if="show_delete_option_modal"
								:option="option"
								@option-deleted="refreshAssetView"
								@close-modal="show_delete_option_modal = false"
							/>
						</Transition>
					</Teleport>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
import ApprovalsComponent from '../ApprovalsComponent.vue';
import AssetHistoryComponent from '../AssetHistoryComponent.vue';
import CommentFormComponent from '../CommentFormComponent.vue';
import CommentsComponent from '../CommentsComponent.vue';
import DeleteOptionModalComponent from '../modals/DeleteOptionModalComponent.vue';

import moment from 'moment';
import { useDataStore } from '../../stores/data';

export default {
	components: {
		ApprovalsComponent,
		AssetHistoryComponent,
		CommentFormComponent,
		CommentsComponent,
		DeleteOptionModalComponent
	},
	props: {
		expand: { required: true, type: Boolean }
	},
	emits: [ 'set-expand' ],
	setup() {
		const {
			getCommentsByOptionId,
			getOptionByOptionId,
			getAssetById,
			isUserStaffSuperAdmin,
			isProjectCompleted
		} = useDataStore();

		return {
			getCommentsByOptionId,
			getOptionByOptionId,
			getAssetById,
			isUserStaffSuperAdmin,
			isProjectCompleted
		};
	},
	data() {
		return {
			loading: false,
			active_tab: 'comments',
			project_is_completed: false,
			asset: null,
			option: null,
			comments: [],
			date_sort: 'desc',
			user_is_staff_super_admin: false,
			show_delete_option_modal: false
		};
	},
	computed: {
		option_id() {
			return this.$route.query.option || null;
		},
		asset_id() {
			return this.$route.params.asset_id || null;
		},
		project_id() {
			return this.$route.params.project_id || null;
		},
		asset_is_final() {
			return this.asset && this.asset.final_revision.length;
		},
		option_is_staff_unapproved() {
			return this.option && !this.option.staff_approval_user.length;
		},
		option_is_in_old_stage() {
			return this.newest_approved_stage && !this.newest_approved_stage.revision_options.find( option => option.id === this.option.id );
		},
		newest_approved_stage() {
			return this.getNewestApprovedRevision();
		},
		option_uploader() {
			if ( !this.option ) {
				return 'Unknown user';
			}
			return this.option.author && this.option.author.fullName ? this.option.author.fullName : 'Unknown user';
		},
		option_artist() {
			if ( !this.option || !this.option.artist.length ) {
				return 'Unknown artist';
			}
			return this.option.artist[0].fullName ?? 'Unknown artist';
		},
		option_upload_date() {
			if ( !this.option ) {
				return null;
			}
			return moment( new Date( this.option.postDate ) ).format( 'D/M/YY' );
		},
		title_array() {
			if ( !this.option ) {
				return [];
			}
			return this.option.title.split( ' - ' );
		},
		sidebar_title() {
			if ( this.option ) {
				switch ( this.active_tab ) {
					case 'comments':
						return 'Discussion';
					case 'approvals':
						return 'Approvals';
					case 'history':
						return 'Asset history';
					case 'settings':
						return 'Settings';
					default:
						return 'No content';
				}
			}
			return 'No content';
		},
		image_title() {
			if ( !this.option ) {
				return '';
			}
			return this.getImageTitle();
		}
	},
	watch: {
		async option_id( new_val, old_val ) {
			if ( new_val !== old_val ) {
				this.loading = true;
				await this.getSidebarContent();
				this.date_sort = 'desc';
				this.loading = false;
			}
		}
	},
	async mounted() {
		this.user_is_staff_super_admin = await this.isUserStaffSuperAdmin();
		this.project_is_completed = await this.isProjectCompleted( this.project_id );

		this.loading = true;
		await this.getSidebarContent();
		this.loading = false;

		this.$emit( 'set-expand', true );
		if ( this.$route.query.show_comments ) {
			this.toggleActiveTab( 'comments' );
		}
		if ( this.$route.query.show_approvals ) {
			this.toggleActiveTab( 'approvals' );
		}
	},
	methods: {
		async getSidebarContent() {
			await this.getOption();
			await this.getComments();
			await this.getAsset();
		},
		async getAsset() {
			if ( !this.asset_id ) {
				return;
			}
			try {
				const asset_response = await this.getAssetById(
					parseInt( this.asset_id )
				);
				this.asset = asset_response.data.entry;
				this.asset.asset_revisions.sort( ( a, b ) => {
					if ( a.revision_number[0] < b.revision_number[0] ) {
						return -1;
					}
					if ( a.revision_number[0] > b.revision_number[0] ) {
						return 1;
					}
					return parseInt( a.revision_number.slice( 1 ) ) - parseInt( b.revision_number.slice( 1 ) );
				} );
			} catch ( error ) {
				// console.error( error );
			}
		},
		async getOption() {
			if ( !this.option_id ) {
				return;
			}
			try {
				const option_response = await this.getOptionByOptionId(
					parseInt( this.option_id )
				);
				this.option = option_response.data.entry;
			} catch ( error ) {
				// console.error( error );
			}
		},
		async getComments() {
			if ( !this.option_id ) {
				return;
			}
			try {
				const response = await this.getCommentsByOptionId(
					parseInt( this.option_id )
				);
				this.comments = response.data.entries;
			} catch ( error ) {
				// console.error( error );
			}
		},
		getImageTitle() {
			if ( !this.option ) {
				return [ 'No option selected', '' ];
			}
			return [ this.title_array[0], `Stage ${ this.title_array.at( -2 ) } - Option ${ this.title_array.at( -1 ) }` ];
		},
		toggleActiveTab( slug ) {
			if ( this.active_tab === slug ) {
				this.$emit( 'set-expand', !this.expand );
				return;
			}
			this.active_tab = slug;
			this.$emit( 'set-expand', true );
		},
		toggleDateSort() {
			this.date_sort = this.date_sort === 'desc' ? 'asc' : 'desc';
			this.comments.reverse();
		},
		async showApprovals() {
			this.active_tab = 'approvals';
			this.loading = true;
			await this.getOption();
			await this.getAsset();
			this.loading = false;
			this.$emit( 'set-expand', true );
		},
		getNewestApprovedRevision() {
			if ( !this.asset ) {
				return null;
			}

			const newest_approved_revision = [ ...this.asset.asset_revisions ]
				.reverse()
				.find( revision => revision.revision_options.find( option => option.staff_approval_user.length ) )
			;

			return newest_approved_revision || null;
		},
		refreshAssetView() {
			this.show_delete_option_modal = false;

			this.$router.replace( {
				name: 'projects__project__asset',
				params: {
					project_id: this.$route.params.project_id,
					asset_id: this.$route.params.asset_id
				},
				query: {
					refresh: 1
				}
			} );
		}
	}
};
</script>
