<template>
	<Transition name="fade">
		<PageLoaderComponent
			v-if="loading"
		/>
		<div
			v-else
			class="a6-12 c8-12 f6-6 mb-64"
		>
			<div class="flex flex--gap-large flex--align-center mb-32">
				<LightswitchComponent
					label="My projects only"
					off-label="My projects only"
					:checked="my_projects_only"
					:disabled="loading || setting_notifications"
					@change="toggleMyProjectsOnly"
				/>
			</div>

			<table class="notification-settings">
				<tr class="notification-settings__header">
					<th align="start">Project</th>
					<th align="end">Emails</th>
				</tr>
				<TransitionGroup
					name="list"
					tag="tbody"
				>
					<tr
						v-for="project in filtered_projects"
						:key="project.id"
						class="notification-settings__row"
					>
						<td align="start">
							{{ project.title }}
						</td>
						<td align="end">
							<LightswitchComponent
								label="On"
								off-label="Off"
								justify="end"
								:checked="project.emails"
								:disabled="setting_notifications"
								@change="value => toggleEmails( project.id, value )"
							/>
						</td>
					</tr>
				</TransitionGroup>
			</table>
		</div>
	</Transition>
</template>

<script>
import LightswitchComponent from './LightswitchComponent.vue';
import PageLoaderComponent from './PageLoaderComponent.vue';

import { nextTick } from 'vue';
import { useDataStore } from '../stores/data';
import { storeToRefs } from 'pinia';

export default {
	components: {
		LightswitchComponent,
		PageLoaderComponent
	},
	setup() {
		const data_store = useDataStore();
		const { user_profile } = storeToRefs( data_store );
		const {
			setEmailNotifications,
			getProjectTitles,
			getUserRelatedNotificationsCount
		} = data_store;

		return {
			setEmailNotifications,
			getProjectTitles,
			getUserRelatedNotificationsCount,
			user_profile
		};
	},
	data() {
		return {
			loading: false,
			setting_notifications: false,
			projects: [],
			my_projects_only: true,
		};
	},
	computed: {
		filtered_projects() {
			if ( this.my_projects_only ) {
				return this.projects.filter( project => project.users_on_project.includes( this.user_profile.id ) )
			}
			return this.projects;
		}
	},
	async mounted() {
		this.loading = true;
		await this.getProjects();
		this.loading = false;
	},
	methods: {
		async getProjects() {
			const projects = await this.getProjectTitles();
			this.projects = this.transformProjectsData( projects );
		},
		transformProjectsData( projects ) {
			return projects.map( project => {
				return {
					id: project.id,
					title: project.title,
					users_on_project: [
						...project.business_development.map( user => user.id ),
						...project.account_director.map( user => user.id ),
						...project.project_manager.map( user => user.id ),
						...project.lead_artist.map( user => user.id ),
						...project.project_artists.map( user => user.id ),
						...project.clients.map( user => user.id )
					],
					emails: !!this.user_profile.email_notification_projects.find( notification_project => notification_project.id === project.id ),
				};
			} );
		},
		async toggleEmails( project_id, value ) {
			const project_index = this.projects.indexOf( this.projects.find( project => project.id === project_id ) )
			this.projects[ project_index ].emails = value;
			await nextTick();

			this.setting_notifications = true;
			await this.setEmailNotifications( this.projects
				.filter( project =>  project.emails )
				.map( project =>  parseInt( project.id ) )
			);
			// await this.getProjects();
			this.setting_notifications = false;
		},
		async toggleMyProjectsOnly() {
			this.my_projects_only = !this.my_projects_only;
		}
	}
};
</script>
