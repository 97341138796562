import { openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    ($setup.user_is_staff)
      ? (_openBlock(), _createBlock($setup["UserEventCardComponent"], {
          key: 0,
          heading: "The Boundary",
          user: $setup.option_is_staff_approved ? $props.option.staff_approval_user[0] : null,
          "event-label": $setup.staff_approval_label,
          "event-is-pending": !$setup.option_is_staff_approved
        }, null, 8 /* PROPS */, ["user", "event-label", "event-is-pending"]))
      : _createCommentVNode("v-if", true),
    _createVNode($setup["UserEventCardComponent"], {
      heading: "Client",
      user: $setup.option_is_client_approved ? $props.option.client_approval_user[0] : null,
      "event-label": $setup.client_approval_label,
      "event-is-pending": !$setup.option_is_client_approved
    }, null, 8 /* PROPS */, ["user", "event-label", "event-is-pending"])
  ], 64 /* STABLE_FRAGMENT */))
}