import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.status_updates, (update, i) => {
    return (_openBlock(), _createBlock($setup["UserEventCardComponent"], {
      key: `status-update-${i}`,
      heading: $setup.ucFirst( update.updated_status ),
      user: update.user && update.user.length ? update.user[0] : null,
      "event-label": $setup.getDate( update.timestamp ),
      "event-is-pending": false
    }, null, 8 /* PROPS */, ["heading", "user", "event-label"]))
  }), 128 /* KEYED_FRAGMENT */))
}