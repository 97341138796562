import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "user-event-card" }
const _hoisted_2 = { class: "user-event-card__title" }
const _hoisted_3 = { class: "user-event-card__content" }
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "user-event-card__details" }
const _hoisted_6 = {
  key: 0,
  class: "user-event-card__name"
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("h4", _hoisted_2, _toDisplayString($props.heading), 1 /* TEXT */),
    _createElementVNode("div", _hoisted_3, [
      (!$props.eventIsPending && $props.user && $props.user.photo)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            src: $props.user.photo.url,
            class: "user-event-card__photo"
          }, null, 8 /* PROPS */, _hoisted_4))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["user-event-card__photo", $props.eventIsPending ? 'user-event-card__photo--event-pending' : 'user-event-card__photo--placeholder'])
          }, null, 2 /* CLASS */)),
      _createElementVNode("div", _hoisted_5, [
        (!$props.eventIsPending)
          ? (_openBlock(), _createElementBlock("p", _hoisted_6, _toDisplayString($props.user.fullName), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        _createElementVNode("p", {
          class: _normalizeClass(["user-event-card__event", { 'user-event-card__event--pending': $props.eventIsPending }])
        }, _toDisplayString($props.eventLabel), 3 /* TEXT, CLASS */)
      ])
    ])
  ]))
}